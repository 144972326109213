import { withZeroPrefix } from '@legacy-studio/library';

type TimerType = 'seconds' | 'clock' | 'fullClock';
type TimerDirection = 'up' | 'down';
export interface TimerOptions {
	type: TimerType;
	count: number;
	initial?: number;
	direction?: TimerDirection;
	onFinish?: () => void;
}

export class Timer {
	private type: TimerType;
	private direction: TimerDirection;
	private timer: NodeJS.Timer | null = null;
	private seconds = 0;
	private count: number;
	private onFinish?: TimerOptions['onFinish'];

	constructor(options: TimerOptions) {
		const { type, count, direction = 'up', onFinish, initial } = options;
		this.type = type;
		this.direction = direction;
		this.count = count;
		this.seconds = initial || 0;
		this.onFinish = onFinish;
	}

	private tick() {
		if (this.isFinish) {
			return;
		}

		this.seconds += 1;

		if (this.isFinish) {
			this.onFinish?.();
			this.stop();
		}
	}

	private get secondsValue() {
		if (this.direction === 'up') {
			return this.seconds;
		}
		else {
			return this.count - this.seconds;
		}
	}

	private get clockValue() {
		const diff
      = this.direction === 'up' ? this.seconds : this.count - this.seconds;

		const daysUp = Math.floor(diff / 3600 / 24);
		const isMoreThenDay = daysUp >= 1;

		if (isMoreThenDay) {
			return `${daysUp}d`;
		}

		const hoursUp = Math.floor(diff / 3600);
		const isMoreThenHour = hoursUp >= 1;

		if (isMoreThenHour) {
			return `${hoursUp}h`;
		}

		const seconds = Math.max(Math.floor(diff % 60), 0);
		const minutes = Math.max(Math.floor(diff / 60), 0);

		return `${withZeroPrefix(minutes)}:${withZeroPrefix(seconds)}`;
	}

	private get clockFullTimeValue() {
		const diff
      = this.direction === 'up' ? this.seconds : this.count - this.seconds;

		const days = Math.floor(diff / 3600 / 24);
		const hours = Math.floor((diff / 3600) % 24);
		const minutes = Math.max(Math.floor((diff / 60) % 60), 0);
		const seconds = Math.max(Math.floor(diff % 60), 0);

		return `${withZeroPrefix(days)}d : ${withZeroPrefix(
      hours,
    )}h : ${withZeroPrefix(minutes)}m:${withZeroPrefix(seconds)}s`;
	}

	get value() {
		if (this.type === 'seconds') {
			return this.secondsValue;
		}
		else if (this.type === 'clock') {
			return this.clockValue;
		}
		else if (this.type === 'fullClock') {
			return this.clockFullTimeValue;
		}
	}

	get percent() {
		return (this.seconds / this.count) * 100;
	}

	get isFinish() {
		return this.seconds >= this.count;
	}

	public start() {
		this.timer = setInterval(this.tick.bind(this), 1000);
	}

	public stop() {
		this.timer && clearInterval(this.timer);
	}

	public reset() {
		this.seconds = 0;
	}
}
